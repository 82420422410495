var AssignTrainingFromModal = (function (window, document) {

    var assign = function (selector) {
        var trainingForm = $(selector).find('#assign-training');
        var trainingData = trainingForm.serialize();
        var trainingPath = trainingForm.attr('action');
        var modalCustomerId = $(selector).attr('id');

        $(selector).on('click','#submit-training', function(e) {
            e.preventDefault();

            $.post(trainingPath, trainingData).done(function(data) {
                console.log(data);

                $(selector).modal('hide');

                if($('#customer-data').data('id') == modalCustomerId) {
                    location.reload();
                }
            });
        });
    };

    return{assign: assign};

})(window, document);