var checkInSimulator = (function (window, document) {

	var simulate = function (intervalSpeed) {
		window.setInterval(function() {

			var typeArray = [
				'warning',
				'success',
				'error'
			];
			var randomIndex = Math.floor(Math.random()*typeArray.length);

			checkInNotification.show(typeArray[randomIndex]);

		}, (intervalSpeed) ? intervalSpeed : 6000);
	};

	return{simulate: simulate};

})(window, document);
