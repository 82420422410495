var removeTitleNotification = (function (window, document) {

	var ready = function () {
		$('.modal').on('hide.bs.modal', function () {
			pageTitleNotification.off();
		});
	};

	return{ready: ready};

})(window, document);