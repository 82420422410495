var serviceBookingDate = (function (window, document) {

	var calcServiceBookingDate = function () {
		var baseUrl = $('#info-base-url').data('base');
		var serviceBookingPath = {
			path: '/leistungsbuchung/calc-service-end-date/'
		};

		$('#medocloudbundle_servicebooking_serviceType').change(function() {

			var serviceType = this.value;
			var bookingDate = $('#medocloudbundle_servicebooking_bookingDate').val();

			ajaxCalcDate(baseUrl, serviceBookingPath, serviceType, bookingDate);
		})

		$('#medocloudbundle_servicebooking_bookingDate').change(function() {
			var serviceType = $('#medocloudbundle_servicebooking_serviceType').val();
			var bookingDate = $(this).val();

			ajaxCalcDate(baseUrl, serviceBookingPath, serviceType, bookingDate);
		})

		function ajaxCalcDate(baseUrl, serviceBookingPath, serviceType, bookingDate) {
			$.ajax({
				url: baseUrl + serviceBookingPath.path + serviceType + '/',
				type: 'POST',
				data: {
					'bookingDate': bookingDate
				},
				beforeSend: function() {
					Pace.restart();
				},
				error: function (response, status, error) {
					console.log(response, status, error);
				},
				success: function (data) {

					$('#medocloudbundle_servicebooking_endDate').val(data);
					$('#medocloudbundle_servicebooking_endDate').trigger( 'keyup' );

				}
			});
		}
	}


	return{calcServiceBookingDate: calcServiceBookingDate};
})(window, document);


