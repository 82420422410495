(function ($) {
    $('document').ready(function() {

	    // translation for dataTables
	    var languageDtGer = {
		    "sEmptyTable": "Keine Einträge vorhanden",
		    "sInfo": "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
		    "sInfoEmpty": "0 bis 0 von 0 Einträgen",
		    "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
		    "sInfoPostFix": "",
		    "sInfoThousands": ".",
		    "sLengthMenu": "_MENU_ anzeigen",
		    "sLoadingRecords": "Wird geladen...",
		    "sProcessing": "Bitte warten...",
		    "sSearch": "Suchen",
		    "sZeroRecords": "Keine Einträge vorhanden.",
		    "oPaginate": {
			    "sFirst": "Erste",
			    "sPrevious": "Zurück",
			    "sNext": "Nächste",
			    "sLast": "Letzte"
		    },
		    "oAria": {
			    "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
			    "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
		    }
	    };

	    $('.dataTable').dataTable({
		    language: languageDtGer,
	    });
	    $('.datepicker').datepicker({language: 'de', autoclose: true});

	    userReaction.button($('.button-load'));
//! moment.js locale configuration (daterangepicker)
//! locale : german (de)
//! author : lluchs : https://github.com/lluchs
//! author: Menelion Elensúle: https://github.com/Oire



		    function processRelativeTime(number, withoutSuffix, key, isFuture) {
			    var format = {
				    'm': ['eine Minute', 'einer Minute'],
				    'h': ['eine Stunde', 'einer Stunde'],
				    'd': ['ein Tag', 'einem Tag'],
				    'dd': [number + ' Tage', number + ' Tagen'],
				    'M': ['ein Monat', 'einem Monat'],
				    'MM': [number + ' Monate', number + ' Monaten'],
				    'y': ['ein Jahr', 'einem Jahr'],
				    'yy': [number + ' Jahre', number + ' Jahren']
			    };
			    return withoutSuffix ? format[key][0] : format[key][1];
		    }

		    var de = moment.defineLocale('de', {
			    months : 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
			    monthsShort : 'Jan._Febr._Mrz._Apr._Mai_Jun._Jul._Aug._Sept._Okt._Nov._Dez.'.split('_'),
			    weekdays : 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
			    weekdaysShort : 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
			    weekdaysMin : 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
			    longDateFormat : {
				    LT: 'HH:mm',
				    LTS: 'HH:mm:ss',
				    L : 'DD.MM.YYYY',
				    LL : 'D. MMMM YYYY',
				    LLL : 'D. MMMM YYYY HH:mm',
				    LLLL : 'dddd, D. MMMM YYYY HH:mm'
			    },
			    calendar : {
				    sameDay: '[Heute um] LT [Uhr]',
				    sameElse: 'L',
				    nextDay: '[Morgen um] LT [Uhr]',
				    nextWeek: 'dddd [um] LT [Uhr]',
				    lastDay: '[Gestern um] LT [Uhr]',
				    lastWeek: '[letzten] dddd [um] LT [Uhr]'
			    },
			    relativeTime : {
				    future : 'in %s',
				    past : 'vor %s',
				    s : 'ein paar Sekunden',
				    m : processRelativeTime,
				    mm : '%d Minuten',
				    h : processRelativeTime,
				    hh : '%d Stunden',
				    d : processRelativeTime,
				    dd : processRelativeTime,
				    M : processRelativeTime,
				    MM : processRelativeTime,
				    y : processRelativeTime,
				    yy : processRelativeTime
			    },
			    ordinalParse: /\d{1,2}\./,
			    ordinal : '%d.',
			    week : {
				    dow : 1, // Monday is the first day of the week.
				    doy : 4  // The week that contains Jan 4th is the first week of the year.
			    },
			    applyLabel: 'Abfragen'
		    });

		    //return de;


		$('.date-range-picker').daterangepicker(
			{
				locale: 'de',
				format:  'DD.MM.YYYY'
			}
		);



	    cardReaderSocket.connect();

	    //checkInSimulator.simulate(120000);
	    search.simulate();

//#############################################################


	   // var socket = new WebSocket('ws://medora-prototyp.neueseite.eu:8080/', 'echo-protocol');

// listening for server response
	    /**
	    socket.onmessage = function (message) {

		    if(window.location.href.indexOf("showCustomer") > -1) {
			    window.location.href = "http://medora-prototyp.neueseite.eu/showCustomerWithAssignedCard.php";
		    } else {
			    switch(message.data){
				    case "04 35 9A CA 2C 49 80 ":
					    //alert("Kunde1");
					    checkInNotification.show('success');
					    break;
				    case "04 54 E1 CA 2C 49 81 ":
					    //alert("Kunde2");
					    checkInNotification.show('warning');
					    break;
				    case "04 0F AF CA 2C 49 81 ":
					    //alert("Kunde 3");
					    checkInNotification.show('error');
					    break;
				    default:
				    //alert(message.data);
			    }
		    }

			console.log(message.data);
		    //serverContent.innerHTML += message.data + '\n';
	    };

// listening for any socket error
	    socket.onerror = function (error) {
		    console.log('WebSocket error: ' + error);
	    };
**/
//############################################################

	    $('#login-group').on('change', function(){
	    	$('#login-button').attr('href',$('#login-group').val());
	    });

	    serviceBookingDate.calcServiceBookingDate();
	    dataTableHandler.linkRows();
	    //client.connect();



        /**
         * Remove modal dialog
         *
         * @param selctor
         */
        function removeModalBySelector(selector) {
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        }

        $('.js-simulate-checkin').on('click', function(e){
        	var endpoint = $(this).attr('href');

            // Request customer information from server
            $.ajax({
                url: endpoint,
                type: 'GET',
                beforeSend: function() {
                    Pace.restart();
                },
                error: function (response, status, error) {
                    console.log(response, status, error);
                },
                success: function (data) {
                    var checkInDialogSelector = '.customer-check-in-dialog .modal';
                    // remove old check in dialog
                    removeModalBySelector(checkInDialogSelector);
                    // add check in dialog
                    $('body').append(data);
                    var $customerCheckInDialog = $(checkInDialogSelector);
                    $customerCheckInDialog.modal({backdrop: 'static', keyboard: false});
                    $customerCheckInDialog.modal('show');
                    AssignTrainingFromModal.assign(checkInDialogSelector);
                    ReloadCheckInClose.checkPage(checkInDialogSelector);
                    $(checkInDialogSelector).on('hidden.bs.modal', function () {
                        $(this).parent().remove();
                    });
                    pageTitleNotification.on('Besuch', 1000);
                    browserNotification.pushNotification('Besuch');

                    removeTitleNotification.ready();
                }
            });

            e.preventDefault();
        });


    });
})(jQuery); 