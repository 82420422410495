var dataTableHandler = (function (window, document) {

	var linkRows = function () {
		$('#customer-data-table').on('click','tr', function() {
			var href = $(this).data('href');
			if(href) {
				window.location = href;
			}
		});
	};

	return{linkRows: linkRows};

})(window, document);
