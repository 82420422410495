var userReaction = (function (window, document) {
	var button = function(thisTarget) {
		thisTarget.on('click', function () {
			$(this).find('i').attr('class', '').addClass('fa fa-refresh fa-spin');
		});
	};

	return{button: button};
})(window, document);

