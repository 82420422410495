

var browserNotification = (function (window, document) {

	var pushNotification = function (message, title) {
		title = title || "Kundenbesuch!";
		var baseUrl = $('#info-base-url').data('base');

		Push.create(title, {
			body: message,
			icon:{
				x16: baseUrl + '/bundles/MedoCloud/Img/favicon-16x16.png',
				x32: baseUrl + '/bundles/MedoCloud/Img/favicon-32x32.png'
			},
			timeout: 16000,
			onClick: function () {
				window.focus();
				this.close();
			}
		});
	};

	return{pushNotification: pushNotification};

})(window, document);
