var search = (function (window, document) {

	var getUrlParameter = function getUrlParameter(sParam) {
		var sPageURL = decodeURIComponent(window.location.search.substring(1)),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : sParameterName[1];
			}
		}
	};

	var simulate = function () {
		var searchString = getUrlParameter('search');
		var $dataTableSearchBox = $(".dataTables_wrapper .dataTables_filter input");

		$dataTableSearchBox.val(searchString);
		$dataTableSearchBox.trigger('keyup');
	};

	return{simulate: simulate};

})(window, document);
