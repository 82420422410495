var ReloadCheckInClose = (function (window, document) {

    var checkPage = function (selector) {
        var modalCustomerId = $(selector).attr('id');
        $(selector).on('click','.close-btn', function(e) {
            if($('#customer-data').data('id') == modalCustomerId) {
                location.reload();
            }
        });
    };

    return{checkPage: checkPage};

})(window, document);