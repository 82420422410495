var checkInNotification = (function (window, document) {

	var show = function (type) {
		var message = "Herr Max Mustermann ist da!"

		switch (type) {
			case 'success':
				$(".customer-green-dialog")
					.modal({backdrop: 'static', keyboard: false})
					.modal("show");
				break;
			case 'warning':
				$(".customer-yellow-dialog")
					.modal({backdrop: 'static', keyboard: false})
					.modal("show");
				message += "\nBitte Beratung durchführen!";
				break;
			case 'error':
				$(".customer-red-dialog")
					.modal({backdrop: 'static', keyboard: false})
					.modal("show");
				message += "\nNicht einlassen! Bitte Beratung durchführen!";
				break;
			default:
				return false;
		}

		pageTitleNotification.on('Besuch', 1000);
		browserNotification.pushNotification(message);

		removeTitleNotification.ready();
	};

	return{show: show};

})(window, document);

